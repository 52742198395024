import React from 'react'
import {PageProps, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Team = (props: PageProps) => (
  <Layout>
    <SEO title="Het team" />
    <h5> Het bestuur </h5>
    <ul>
      <li>Twan Burghouts (Voorzitter)</li>
      <li>Ralf Klein Breteler (Penningmeester) </li>
      <li>Bert Hulsebos (Secretaris)</li>
      <li>Joost Scholte (Culturele Activiteiten)</li>
    </ul>
    <h5>De lesgevers</h5>
    <ul>
      <li>peuters / groep 1: meester Hans-Peter, meester Pieter</li>
      <li>groep 2: meester Mark</li>
      <li>groep 4 - 8: juf Margo, juf Milena</li>
      <li>groep VO: juf Margo</li>
    </ul>
  </Layout>
)

export default Team
